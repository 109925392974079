.App {
  min-height: 100vh;
  min-width: 100%;
}

.App .Background {
  min-height: 100vh;
  min-width: 100%;
  position: fixed;
  display: flex;
  flex-direction: column;
}

.App .Background .Band {
  width: 100%;
  height: calc(100vh / 6);
  position: relative;
  display: block;
  content: "";
}

.App .Main .Header {
  text-align: center;
}

.App .Main .Header .Logo {
  width: 80%;
  max-width: 500px;
  height: 71px;
  margin: 2rem auto;
  cursor: pointer;
}

.App .Background .Band.Red {
  background-color: #cb2026;
}
.App .Background .Band.Orange {
  background-color: #f26722;
}
.App .Background .Band.Yellow {
  background-color: #fecd06;
}
.App .Background .Band.Green {
  background-color: #36b54e;
}
.App .Background .Band.Blue {
  background-color: #2F67B1;
}
.App .Background .Band.Purple {
  background-color: #6535af;
}

.App .Main {
  position: relative;
  min-height: calc(100vh * (5 / 6));
  width: 97%;
  margin: 0 auto;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  overflow: hidden;
}

.App .Main .Content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3rem auto;
}

.App .Main .Content .Title {
  font-size: 3em;
  line-height: .9em;
  margin: 0;
  font-weight: 500;
  font-family: 'Segoe UI Regular';
  padding: inherit auto 0 auto;
}

.App .Main .Content .Subtitle {
  font-size: 1.7em;
  text-align: center;
  font-weight: 500;
  margin: 10px auto;
  font-family: 'Segoe UI Semilight';
}

.App .Main .Content .MainLine {
  display: block;
  content: "";
  height: 1.7rem;
  font-size: 1.7rem;
  line-height: .9em;
  margin: 0;
  font-weight: 500;
  text-align: center;
  font-family: 'Segoe UI Regular';
  padding: inherit auto 0 auto;
}

.App .Main .Content .ButtonContainer {
  margin: 10px 15px;
  height: 70px;
  display: flex;
}

.App .Main .Content .BackButton {
  color: #2F67B1;
  text-decoration: underline;
  cursor: pointer;
  user-select: none;
}

.App .Main .Content .SubLine {
  font-size: 1.6em;
  font-weight: 500;
  margin: 0 auto;
  text-align: center;
  font-family: 'Segoe UI Semilight';
}

.App .Main .Content .Results {
  transform: translateX(-50%);
  animation: ResultsBounce 1s ease;
  font-size: 3.5rem;
  line-height: 3.5rem;
  height: 3.5rem;
  overflow: visible;
  z-index: 999999;
  font-family: 'Segoe UI Bold';
  text-transform: uppercase;
  text-align: center;
  margin: 4rem auto;
  margin-left: 50%;
}

@keyframes ResultsBounce {
  0% { font-size: 0rem; }
  35% { font-size: 15rem; }
  50% { font-size: 3.5rem; }
  70% { font-size: 4rem; }
  85% { font-size: 3rem; }
  100% { font-size: 3.5rem; }
}

.App .Main .Content .AnswersBox {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 3rem auto;
}

.App .Main .Content .AnswersBox .ForceMargin {
  margin: 10px 15px;
  height: 70px;
  display: flex;
}

.App .Footer {
  height: calc((100vh / 6) - 1px);
  position: relative;
  width: 97%;
  margin: 0 auto;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.5), inset 0 0 10px rgba(0,0,0,.08);
  border-top: 1px solid rgb(228, 228, 228);
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

@media screen and (min-width: 760px) {
  .App .Main {
    border-radius: 7px 7px 0 0;
  }
  .App .Footer {
    border-radius: 0 0 7px 7px;
  }
}
