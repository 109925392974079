.Progress {
  width: 350px;
  max-width: 90%;
  height: 30px;
  overflow: hidden;
  position: relative;
  display: inline-block;
  content: "";
  background-color: #e0e0e0;
  box-shadow: inset 0 0 5px 0 rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  font-weight: 800;
  text-decoration: none;
  font-size: 1.5rem;
  cursor: pointer;
  z-index: 99999;
  user-select: none;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  margin: 0 auto 2rem auto;
}

.Bar {
  height: 100%;
  background-color: #36b54e;
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.25), 0 -5px 0 hsl(131, 54%, 26%) inset;
  transition: width 0.2s ease-in-out;
  color: #fff;
  text-align: center;
  line-height: 25px;
  font-weight: 500;
  font-family: "Segoe UI Regular";
  font-size: 20px;
}
